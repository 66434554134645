import React from 'react'
import './footer.css'
import {BsLinkedin} from 'react-icons/bs'
import {BsGithub} from 'react-icons/bs'


const Footer = () => {
  return (
    <footer>
      <a href="#" className='footer_logo'>ENZO</a>

      <ul className='permalinks'>
        <li><a href="#">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#techstack">Tech Stack</a></li>
        <li><a href="#portfolio">Portfolio</a></li>
        <li><a href="#contact">Contact</a></li>
      </ul>

      <div className="footer_socials">
        <a href="https://www.linkedin.com/in/Enzo-He/" target="_blank"><BsLinkedin/></a>
        <a href="https://github.com/Enzo-He" target="_blank"><BsGithub/></a>
      </div>

      <div className="footer_copyright">
        <small>&copy; Enzo. All rights reserved.</small>
      </div>
    </footer>
  )
}

export default Footer