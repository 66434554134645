import React from 'react'
import './techstack.css'
import { BsPatchCheckFill } from 'react-icons/bs'

const Techstack = () => {
  return (
    <section id='techstack'>
      <h5>What Skills I Have</h5>
      <h2>My Technology Stacks</h2>

      <div className="container techstack_container">

        <div className="techstack_frontend">
          <h3>Frontend Development</h3>

          <div className="techstack_content">
            <article className='techstack_details'>
              <BsPatchCheckFill className='techstack_details-icon' />
              <div>
                <h4>HTML</h4>
              </div>
            </article>

            <article className='techstack_details'>
              <BsPatchCheckFill className='techstack_details-icon' />
              <div>
                <h4>CSS</h4>
              </div>
            </article>

            <article className='techstack_details'>
              <BsPatchCheckFill className='techstack_details-icon' />
              <div>
                <h4>JavaScript</h4>
              </div>
            </article>

            <article className='techstack_details'>
              <BsPatchCheckFill className='techstack_details-icon' />
              <div>
                <h4>Bootstrap</h4>
              </div>
            </article>

            <article className='techstack_details'>
              <BsPatchCheckFill className='techstack_details-icon' />
              <div>
                <h4>EJS</h4>
              </div>
            </article>

            <article className='techstack_details'>
              <BsPatchCheckFill className='techstack_details-icon' />
              <div>
                <h4>TypeScript</h4>
              </div>
            </article>

            <article className='techstack_details'>
              <BsPatchCheckFill className='techstack_details-icon' />
              <div>
                <h4>React</h4>
              </div>
            </article>

            <article className='techstack_details'>
              <BsPatchCheckFill className='techstack_details-icon' />
              <div>
                <h4>VUE</h4>
              </div>
            </article>

          </div>
        </div>
        {/* END OF FRONTEND */}

        <div className="techstack_backend">
          <h3>Backend Development</h3>

          <div className="techstack_content">
            <article className='techstack_details'>
              <BsPatchCheckFill className='techstack_details-icon' />
              <div>
                <h4>Node JS</h4>
              </div>
            </article>

            <article className='techstack_details'>
              <BsPatchCheckFill className='techstack_details-icon' />
              <div>
                <h4>MongoDB</h4>
              </div>
            </article>

            <article className='techstack_details'>
              <BsPatchCheckFill className='techstack_details-icon' />
              <div>
                <h4>PHP</h4>
              </div>
            </article>

            <article className='techstack_details'>
              <BsPatchCheckFill className='techstack_details-icon' />
              <div>
                <h4>MySQL</h4>
              </div>
            </article>
          </div>
          {/* END OF BACKEND */}
          <br />
          <div className="techstack_graphic">
            <h3>Graphic Designing</h3>

            <div className="techstack_content">
              <article className='techstack_details'>
                <BsPatchCheckFill className='techstack_details-icon' />
                <div>
                  <h4>UI/UX</h4>
                </div>
              </article>

              <article className='techstack_details'>
                <BsPatchCheckFill className='techstack_details-icon' />
                <div>
                  <h4>Adobe Suite</h4>
                </div>
              </article>

            </div>
          </div>
          {/* END OF BACKEND */}

        </div>
      </div>
    </section>
  )
}

export default Techstack