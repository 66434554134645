import React from 'react'
import './about.css'
import ME from '../../assets/me-about.jpg'
import { FaAward } from 'react-icons/fa'
import { BsStack } from 'react-icons/bs'
import { VscFolderLibrary } from 'react-icons/vsc'

const About = () => {
  return (
    <section id='about'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>


      <div className="container about_container">
        <div className="about_me">
          <div className="about_me-image">
            <img src={ME} alt="About Image" />
          </div>
        </div>

        <div className="about_content">
          <div className="about_cards">
            <a href="#techstack" className='about_card'>
              <BsStack className='about_icon' />
              <h5>Tech_Stack</h5>
              <small>Frontend Backend</small>
            </a>

            <a href="#portfolio" className='about_card'>
              <VscFolderLibrary className='about_icon' />
              <h5>Projects</h5>
              <small>Click+</small>
            </a>

            <article className='about_card'>
              <FaAward className='about_icon' />
              <h5>Education</h5>
              <small>Master of IT</small>
            </article>


          </div>


          <p>
          I am a motivated developer with experience of the projects of building websites with React and VUE. In the projects, I've used HTML, CSS, JavaScript, PHP, MySQL, Bootstrap, EJS, MongoDB, and other elements UIs and APIs to design websites and build different functions. Moreover, I specialise in Adobe Suite including Photoshop, Premiere, After Effects and Dreamweaver, with good artistic aesthetics and photo editing ability, as I have around four years of working experience in graphic design and editing field. I am able to provide one-stop service including product photo shooting, image editing, coding, etc. during the process of website production if necessary.
          </p>

          <a href="#contact" className='btn btn-primary'>Let's Talk</a>
        </div>
      </div>
    </section>
  )
}

export default About