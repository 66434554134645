import React from 'react'
import ReactTooltip from 'react-tooltip'
import {BsLinkedin} from 'react-icons/bs'
import {BsGithub} from 'react-icons/bs'
/* import {RiProjectorFill} from 'react-icons/ri' */

const HeaderSocials = () => {
  return (
    <div className='header_socials'>
        <a href="https://www.linkedin.com/in/Enzo-He/" target="_blank"><BsLinkedin/></a>
        <a href="https://github.com/Enzo-He" target="_blank"><BsGithub/></a>
{/*         <a href="#projects" target="_blank" data-tip = "Forward to My projects"><RiProjectorFill/></a> */}
        <ReactTooltip/>
    </div>
  )
}

export default HeaderSocials