import React from 'react'
import './portfolio.css'
import Popup from './Popup'
import IMG1 from '../../assets/portfolio1.jpg'
import IMG2 from '../../assets/portfolio2.jpg'
import IMG3 from '../../assets/portfolio3.jpg'

import PPIMG1 from '../../assets/fitness.gif'
import PPIMG2 from '../../assets/Travel.gif'
import PPIMG3 from '../../assets/ChatApp.gif'
import { useState } from 'react'


const data = [
  {
    id: 1,
    image: IMG1,
    title: 'Fitness Website',
    github: 'https://github.com/Enzo-He/VW-Fitness',
    ppimg: PPIMG1,
    desc: 'This is a Fitness Website'
  },
  {
    id: 2,
    image: IMG2,
    title: 'Travel Agency',
    github: 'https://github.com/Enzo-He/let-s-travel',
    ppimg: PPIMG2,
    desc: 'Travel Agency+++'
  },
  {
    id: 3,
    image: IMG3,
    title: 'Real Time Chat App',
    github: '  https://github.com/Enzo-He/Chat-App',
    ppimg: PPIMG3,
    desc: 'Travel Agency+++'
  },


]



const Portfolio = () => {
  /*   const [popupcontent, setpopupcontent] = useState([]);
    const changecontent = (data) => {
      setpopupcontent([data])
    } */

  const [buttonPopup, setButtonPopup] = useState(false);

 
/*   const getData = (ppimg, desc) => {
    let tempData = [ppimg, desc];
    console.log(tempData)
}  */

  return (

    <section id='portfolio'>
      <h5>My Rcent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio_container">
        {

          data.map(({ id, image, title, github, ppimg, desc }) => {
            return (
              <article key={id} className="portfolio_item">
                <div className="portfolio_item-image">
                  <img src={image} alt={title} />
                </div>
                <h3>{title}</h3>
                <div className="portfolio_item-cta">
                  <a href={github} className='btn' target='_blank'>Github</a>
                  <a href={ppimg} className='btn btn-primary' target='_blank'>Live Demo</a>
                  {/* <button onClick={() => setButtonPopup(true)} className='btn btn-primary' target='_blank'>More Detail</button> */}

{/*                 <Popup trigger={buttonPopup} setTrigger={setButtonPopup}>
                  <img src={ppimg} />
                  <h1>{desc}</h1>
                </Popup> */}
                </div>

              </article>
            )
          })
        }


      </div>
    </section>
  )
}

export default Portfolio